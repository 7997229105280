import {React, useEffect} from "react";
import { Container, Row, Col } from "reactstrap";
import I1 from '../../assets/images/I1.jpeg';
//import I2 from '../../assets/images/I2.jpeg';
import I3 from '../../assets/images/I3.jpeg';
import I4 from '../../assets/images/I4.jpeg';
import I6 from '../../assets/images/I6.jpeg';
import I7 from '../../assets/images/I7.jpeg';
import I8 from '../../assets/images/I8.jpeg';
import V1 from '../../assets/images/V1.mp4';
import V2 from '../../assets/images/V2.mp4';
import V3 from '../../assets/images/V3.mp4';

import './RajeshSirDetails.css';

const RajeshSirDetails = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    })
    return (
        <section className="master-details-section1">
            <Container>
                <Row className="align-items-center">
                    <Col lg="4" className="master-image-container">
                        <img src={I1} alt="Sensei Rajesh B. Yaragatti" className="master-image12" />
                    </Col>
                    <Col lg="8" className="master-info-container">
                        <h1 className="chief">Chief Instructor </h1>
                        <h2 className="master-name1">Sensei: Rajesh B. Yaragatti</h2>
                        <p className="master-description1">
                            Sensei: Rajesh B. Yaragatti began his karate journey in 1997 in Shiggaon. 
                            Later, he moved to Hubballi and in 2002, he joined under the guidance 
                            of Shihan Pradeep Kumar. His dedication and passion for karate have 
                            made him an exceptional instructor and a respected figure in the community.
                        </p>
                    </Col>
                </Row>
                <Row className="media-gallery1">
                    
                    <Col lg="4" md="6" sm="12" className="gallery-item1">
                        <video controls className="gallery-video">
                            <source src={V1} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Col>
                    <Col lg="4" md="6" sm="12" className="gallery-item1">
                        <img src={I3} alt="Karate Event 2" className="gallery-image" />
                    </Col>
                    <Col lg="4" md="6" sm="12" className="gallery-item1">
                        <video controls className="gallery-video">
                            <source src={V2} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Col>
                    <Col lg="4" md="6" sm="12" className="gallery-item1">
                        <img src={I4} alt="Karate Event 3" className="gallery-image" />
                    </Col>
                    <Col lg="4" md="6" sm="12" className="gallery-item1">
                        <img src={I6} alt="Karate Event 5" className="gallery-image" />
                    </Col>
                    <Col lg="4" md="6" sm="12" className="gallery-item1">
                        <video controls className="gallery-video">
                            <source src={V3} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Col>
                    <Col lg="4" md="6" sm="12" className="gallery-item1">
                        <img src={I7} alt="Karate Event 6" className="gallery-image" />
                    </Col>
                    <Col lg="4" md="6" sm="12" className="gallery-item1">
                        <img src={I8} alt="Karate Event 7" className="gallery-image" />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default RajeshSirDetails;
