import {React,useEffect} from "react";
import { Container, Row, Col } from "reactstrap";
import I11 from '../../assets/images/I11.jpeg';
import I12 from '../../assets/images/I12.jpeg';
//import I13 from '../../assets/images/I13.jpeg';
import I14 from '../../assets/images/I14.jpeg';



import './PradeepSirDetails.css';

const PradeepSirDetails = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    })
    return (
        <section className="master-details-section">
            <Container>
                <Row className="align-items-center">
                    <Col lg="4" className="master-image-container">
                        <img src={I11} alt="Sensei Rajesh B. Yaragatti" className="master-image1" />
                    </Col>
                    <Col lg="8" className="master-info-container">
                        <h2 className="master-name">Shihan: Pradeep Kumar</h2>
                        <p className="master-description">
                        Shihan: Pradeep Kumar, born on 28th November 1979 in Tiruvalla, Pathanamthitta District, Kerala, was the beloved son of Somanpillay and Thankamma.
                         He embarked on his karate journey on September 1, 1987, and became an exceptional martial artist. 
                         Alongside his dedication to karate, he was a committed railway employee. Shihan Pradeep Kumar's legacy lives on in our hearts, 
                         and he is deeply missed since his passing on January 19, 2012.
                        </p>
                    </Col>
                </Row>
                <Row className="media-gallery">
                    <Col lg="4" md="6" sm="12" className="gallery-item">
                        <img src={I12} alt="Karate Event 1" className="gallery-image" />
                    </Col>
                    
                    
                    
                    <Col lg="4" md="6" sm="12" className="gallery-item">
                        <img src={I14} alt="Karate Event 3" className="gallery-image" />
                    </Col>
                    
                   
                    
                </Row>
            </Container>
        </section>
    );
};

export default PradeepSirDetails;
