import React from "react";
import "./footer.css";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/shotokan.jpeg";

const Footer = () => {
  const year = new Date().getFullYear();

  const locations = [
    {
      name: 'Shree Siddeshwara Kailasa Mantapa Hosamath P.B Road, Unkal Hubballi-31',
      mapLink: 'https://www.google.co.in/maps/place/Shotokan+karate+sports+club+unkal/@15.3766879,75.1107688,17z/data=!3m1!4b1!4m6!3m5!1s0x3bb8d18c2fa3cd9d:0x50d008c233f0cca1!8m2!3d15.3766879!4d75.1133437!16s%2Fg%2F11qkqvhs5z?entry=ttu',
      timings: [
        'Monday, Wednesday, Friday - Morning: 6:00-7:30 AM, Evening: 6:00-7:30 PM',
      ],
    },
    {
      name: 'Kalmeshwara Temple Samudaya Bhavana, Unkal Hubballi-31',
      mapLink: 'https://www.google.co.in/maps/place/Sri+Kalmeshwar+Temple/@15.3797857,75.114197,18.36z/data=!4m14!1m7!3m6!1s0x3bb8d18c2fa3cd9d:0x50d008c233f0cca1!2sShotokan+karate+sports+club+unkal!8m2!3d15.3766879!4d75.1133437!16s%2Fg%2F11qkqvhs5z!3m5!1s0x3bb8d0d0993613a3:0xf4e07788afe7ae5e!8m2!3d15.3799038!4d75.1154006!16s%2Fg%2F11b75q8jgr?entry=ttu',
      timings: [
        'Tuesday, Thursday, Sunday - Morning: 6:00-7:30 AM',
      ],
    },
    {
      name: 'Dr. B.R. Ambedkar Samudaya Bhavana, Teachers colony, Sai Nagar,Unkal Hubballi-31',
      mapLink: 'https://maps.app.goo.gl/Qwi9NFdLH5DsMnVW6',
      timings: [
        'Tuesday, Thursday, Saturday - Evening: 6:00-7:30 PM',
      ],
    },
  ];

  return (
    <footer className="footer">
      <Container>
        <Row className="justify-content-center">
          <Col lg="4" md="6" className="mb-4">
            <div className="footer-logo">
              <img src={logo} alt="Shotokan Karate-Do Association" />
              <h4 className="footer-quote">
                "Don't fear failure. Not failure, but low aim is the crime."
              </h4>
              <h4 className="footer-quote">
                "In great attempts, it is even glorious to fail."
              </h4>
              <div className="social-links">
                <a href="https://www.youtube.com/@rajeshyaragatti1699" target="_blank" rel="noopener noreferrer">
                  <i className="ri-youtube-fill"></i>
                </a>
                <a href="https://www.instagram.com/shotokankaratedoassociat/" target="_blank" rel="noopener noreferrer">
                  <i className="ri-instagram-fill"></i>
                </a>
                <a href="https://www.facebook.com/COACH.R.B.YARAGATTI" target="_blank" rel="noopener noreferrer">
                  <i className="ri-facebook-fill"></i>
                </a>
              </div>
            </div>
          </Col>
          <Col lg="2" md="3" className="mb-4">
            <h5 className="footer-title">Discover</h5>
            <ul className="footer-links">
              <li><Link to="/home">Home</Link></li>
              <li><Link to="/about">About</Link></li>
            </ul>
          </Col>
        
          <Col lg="3" md="6" className="mb-4">
            <h5 className="footer-title">Contact</h5>
            <ul className="footer-contact">
              <li><i className="ri-map-pin-line"></i>Unkal, Hubballi, Karnataka--580031</li>
              <li><i className="ri-mail-line"></i><a href="mailto:shotokankaratehubli@gmail.com">shotokankaratehubli@gmail.com</a></li>
              <li><i className="ri-phone-line"></i><a href="tel:+919916836930">+91 9916836930</a></li>
            </ul>
          </Col>

          <Col lg="3" md="6" className="mb-4">
            <h5 className="footer-title">Locations</h5>
            <ul className="footer-locations">
              {locations.map((location, index) => (
                <li key={index}>
                  <a href={location.mapLink} target="_blank" rel="noopener noreferrer">
                    {location.name}
                  </a>
                  <p>{location.timings.join(', ')}</p>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="text-center">
            <p className="footer-text">© {year} Shotokan Karate-Do Association. All rights reserved.</p>
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="text-center">
            <p className="footer-designer-info">
              Designed and Maintained by Vinod G Pattar<br />
              Email: <a href="mailto:vinodgpattar@gmail.com">vinodgpattar@gmail.com</a><br />
              Contact No: <a href="tel:+918792683791">+91 87926 83791</a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
