import React, { useEffect } from 'react';
import '../styles/service6.css';

const grades = [
    {
        belt: 'White Belt (10th Kyu)',
        description: 'The beginning level, focusing on basic techniques and stances.',
        katas: [
            {
                name: 'Taikyoku Shodan',
                description: 'Basic kata, often used as an introduction to kata practice.'
            }
        ]
    },
    {
        belt: 'Yellow Belt (9th Kyu)',
        description: 'Introduces basic stances, blocks, and strikes.',
        katas: [
            {
                name: 'Heian Shodan',
                description: 'Focuses on basic stances, blocks, and strikes.'
            }
        ]
    },
    {
        belt: 'Orange Belt (8th Kyu)',
        description: 'Further development of basic techniques, including kicks and different stances.',
        katas: [
            {
                name: 'Heian Nidan',
                description: 'Introduces more advanced techniques, including kicks and different stances.'
            }
        ]
    },
    {
        belt: 'Green Belt (7th Kyu)',
        description: 'Focus on combination techniques and dynamic movement.',
        katas: [
            {
                name: 'Heian Sandan',
                description: 'Emphasizes combination techniques and dynamic movement.'
            }
        ]
    },
    {
        belt: 'Blue Belt (6th Kyu)',
        description: 'Emphasis on balance, coordination, and more complex combinations.',
        katas: [
            {
                name: 'Heian Yondan',
                description: 'Focuses on balance, coordination, and more complex combinations.'
            }
        ]
    },
    {
        belt: 'Purple Belt (5th Kyu)',
        description: 'Introduction of more advanced stances and techniques, including jumps.',
        katas: [
            {
                name: 'Heian Godan',
                description: 'Introduces more advanced stances and techniques, including jumps.'
            }
        ]
    },
    {
        belt: 'Brown Belt (4th Kyu - 1st Kyu)',
        description: 'Preparation for black belt, focusing on lateral movements and complex techniques.',
        katas: [
            {
                name: 'Tekki Shodan',
                description: 'Emphasizes side stances and lateral movements.'
            },
            {
                name: 'Bassai Dai',
                description: 'Demonstrates power and determination through forceful techniques.'
            },
            {
                name: 'Jion',
                description: 'A traditional kata with strong, basic techniques that emphasize power and precision.'
            },
            {
                name: 'Empi',
                description: 'Emphasizes fast, dynamic movements, including jumps.'
            },
        ]
    },
    {
        belt: 'Black Belt (Shodan - 1st Dan)',
        description: 'Mastery of all basic techniques, continuous learning, and introduction to higher-level katas.',
        katas: [
            {
                name: "ji'in",
                description: 'advanced Shotokan kata known for its unique blend of powerful stances and fluid, circular hand techniques, emphasizing control and focus.'
            },
            {
                name: 'Kanku Dai',
                description: 'A longer kata focusing on a wide range of techniques and movements.'
            },
           
            {
                name: 'Hangetsu',
                description: 'Focuses on breathing and slow, controlled movements.'
            },
            {
                name: 'Jitte',
                description: 'Emphasizes defensive techniques against multiple opponents.'
            },
            {
                name: 'Tekki Nidan',
                description: 'Further develops lateral movements and introduces more complex techniques.'
            },
            {
                name: 'Tekki Sandan',
                description: 'Focuses on complex stances and hand techniques.'
            },
        ]
    },
    {
        belt: 'Advanced Black Belt (2nd Dan and above)',
        description: 'Continued advancement with emphasis on complex timing, coordination, and acrobatic movements.',
        katas: [
            {
                name: 'Gankaku',
                description: 'Includes balance-intensive techniques and one-legged stances.'
            },
            {
                name: 'Sochin',
                description: 'Emphasizes powerful stances and techniques with a calm demeanor.'
            },
            {
                name: 'Nijushiho',
                description: 'A complex kata with intricate timing and coordination.'
            },
            {
                name: 'Unsu',
                description: 'Known for its dynamic and acrobatic movements, including a 360-degree jump.'
            },
            {
                name: 'Meikyo',
                description: 'Focuses on reflection and precision, with an emphasis on timing and flow.'
            },
            {
                name: 'Chinte',
                description: 'A kata with unique and unusual techniques, focusing on close-range defense.'
            },
            {
                name: 'Gojushiho Dai',
                description: 'A long and intricate kata with complex movements.'
            },
            {
                name: 'Gojushiho Sho',
                description: 'A shorter version with a focus on subtle movements.'
            },
            {
                name: 'Wankan',
                description: 'The shortest kata in Shotokan, with a focus on simplicity and directness.'
            }
        ]
    }
];

const KarateGrades = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="grades-container">
            {grades.map((grade, index) => (
                <div key={index} className="grade">
                    <h2>{grade.belt}</h2>
                    <p>{grade.description}</p>
                    <h3>Katas:</h3>
                    {grade.katas.map((kata, i) => (
                        <div key={i} className="kata">
                            <h4>{kata.name}</h4>
                            <p>{kata.description}</p>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default KarateGrades;
