import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './RemoveMedia.css';

const RemoveMedia = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
  })
  const [mediaList, setMediaList] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchMedia();
  }, []);

  const fetchMedia = async () => {
    try {
      const response = await axios.get('https://karatesportsclubhubli.com/fetch_media_files.php');
      setMediaList(response.data);
    } catch (error) {
      console.error('Error fetching media:', error);
      setMessage('Failed to fetch media');
    }
  };

  const handleDelete = async (fileName) => {
    try {
      await axios.delete(`https://karatesportsclubhubli.com/delete_media_files.php?file=${encodeURIComponent(fileName)}`);
      setMessage('Media deleted successfully');
      fetchMedia(); // Refresh media list after deletion
    } catch (error) {
      console.error('Error deleting media:', error);
      setMessage('Failed to delete media');
    }
  };

  return (
    <div className="remove-media-container">
      <h2>Remove Media</h2>
      {message && <p>{message}</p>}
      <div className="media-list">
        {mediaList.map((fileName, index) => (
          <div key={index} className="media-item">
            <img src={`https://karatesportsclubhubli.com/media_file.php?file=${encodeURIComponent(fileName)}`} alt={fileName} />
            <button className="remove-button" onClick={() => handleDelete(fileName)}>
              X
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RemoveMedia;
