/** @format */

import React, { useEffect, useContext } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Home from "./../pages/Home";
import Login from "./../pages/Login";
import Register from "./../pages/Register";
import ForgotPassword from "./../components/ForgotPassword";
import AdminDashboard from "./../components/AdminDashboard/AdminDashboard";
import AddStudent from "./../components/AddStudent/AddStudent";
import PasswordReset from "./../components/PasswordReset";
import TakeAttendance from './../components/TakeAttendance/TakeAttendance';
import AddMedia from './../components/AddMedia/AddMedia';
import RemoveStudent from './../components/RemoveStudent/RemoveStudent';
import ServiceSection from './../components/ServiceSection/ServiceSection';
import About from './../components/About/About';
import { AuthContext } from "./../context/AuthContext";
import RetrieveAttendance from './../components/RetrieveAttendance/RetrieveAttendance';
import Introduction from './../components/Introduction/Introduction';
import LoginDashboard from '../components/LoginDashboard/Login';
import StudentProfile from '../components/StudentProfile/StudentProfile';
import RemoveMedia from '../components/RemoveMedia/RemoveMedia';
import RemovedStudents from '../components/RemovedStudents/RemovedStudents';
import EmailMessage from '../components/EmailMessage/EmailMessage';
import RajeshSirDetails from '../components/RajeshSirDetails/RajeshSirDetails';
import PradeepSirDetails from '../components/PradeepSirDetails/PradeepSirDetails';
import AddEvent from '../components/AddEvent/AddEvent';
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";
import BackButtonHandler from "../components/BackButtonHandler/BackButtonHandler";
import Service1 from "../pages/service1";
import Service2 from "../pages/service2";
import Service3 from "../pages/service3";
import Service4 from "../pages/service4";
import Service5 from "../pages/service5";
import Service6 from "../pages/service6";

const Routers = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/service1" element={<Service1 />} />
      <Route path="/service2" element={<Service2 />} />
      <Route path="/service3" element={<Service3 />} />
      <Route path="/service4" element={<Service4 />} />
      <Route path="/service5" element={<Service5 />} />
      <Route path="/service6" element={<Service6 />} />
      {!user && <Route path="/login" element={<Login />} />}
      {!user && <Route path="/register" element={<Register />} />}
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path='/TakeAttendance' element={<PrivateRoute><TakeAttendance /></PrivateRoute>} />
      <Route path='/AdminDashboard' element={<PrivateRoute><AdminDashboard /></PrivateRoute>} />
      <Route path='/AddStudent' element={<PrivateRoute><AddStudent /></PrivateRoute>}/>
      <Route path='/AddMedia' element={<PrivateRoute><AddMedia /></PrivateRoute>}/>
      <Route path='/RemoveStudent' element={<PrivateRoute><RemoveStudent /></PrivateRoute>}/>
      <Route path='/ServiceSection' element={<ServiceSection />}/>
      <Route path='/About' element={<About />}/>
      <Route path='/RetrieveAttendance' element={<PrivateRoute><RetrieveAttendance /></PrivateRoute>}/>
      <Route path='/Introduction' element={<Introduction />} />
      <Route path='/LoginDashboard' element={<LoginDashboard />}/>
      <Route path='/StudentProfile' element={<StudentProfile />} />
      <Route path='/RemoveMedia' element={<PrivateRoute><RemoveMedia/></PrivateRoute>}/>
      <Route path="/RemovedStudents" element={<PrivateRoute><RemovedStudents /></PrivateRoute>} />
      <Route path='/EmailMessage' element={<PrivateRoute><EmailMessage /></PrivateRoute>} />
      <Route path='/RajeshSirDetails' element={<RajeshSirDetails />} />
      <Route path="/PradeepSirDetails" element={< PradeepSirDetails />} />
      <Route path='/AddEvent' element={<PrivateRoute>< AddEvent /></PrivateRoute>} />
      <Route path="/password-reset/:userId/:token" element={<PasswordReset />} />
      <Route path="/BackButtonHandler" element={<BackButtonHandler/>}/>
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );
};

export default Routers;
