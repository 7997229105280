import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './TakeAttendance.css';
import axios from 'axios';

const Attendance = () => {
  const [attendanceDate, setAttendanceDate] = useState(new Date());
  const [students, setStudents] = useState([]);
  const [session, setSession] = useState('morning'); // Default to morning session

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchStudents = async () => {
      try {
        const response = await axios.get('https://karatesportsclubhubli.com/NamesForAttendance.php');
        if (Array.isArray(response.data)) {
          const fetchedStudents = response.data.map(student => ({
            id: student.student_id, // Use student_id from the API response
            name: student.student_name, // Use student_name from the API response
            status: null,
          }));
          setStudents(fetchedStudents);
        } else {
          console.error('Unexpected response format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching students', error);
      }
    };

    fetchStudents();
  }, []);

  const toggleAttendance = (id, status) => {
    setStudents(prevStudents =>
      prevStudents.map(student =>
        student.id === id ? { ...student, status } : student
      )
    );
  };

  const handleSubmit = async () => {
    const allStudentsMarked = students.every(student =>
      ['present', 'absent'].includes(student.status)
    );

    if (!allStudentsMarked) {
      alert('Please mark all students as Present or Absent.');
      return;
    }

      // Get local date as YYYY-MM-DD
  const formattedDate = `${attendanceDate.getFullYear()}-${(
    attendanceDate.getMonth() + 1
  )
    .toString()
    .padStart(2, '0')}-${attendanceDate
    .getDate()
    .toString()
    .padStart(2, '0')}`;

    const attendanceData = students.map(student => ({
      student_id: student.id, // Include student_id in the data
      student_name: student.name,
      session, // Include session in the data
      date: formattedDate,
      status: student.status,
    }));

    try {
      await axios.post('https://karatesportsclubhubli.com/StoreAttendance.php', attendanceData);
      alert('Attendance submitted successfully.');
    } catch (error) {
      console.error('Error submitting attendance:', error);
      alert('There was an error submitting the attendance.');
    }
  };

  return (
    <div className="attendance">
      <h2>Take Attendance</h2>
      <div className="date-picker-container">
        <label className="date-picker-label">Select Attendance Date: </label>
        <DatePicker
          selected={attendanceDate}
          onChange={date => setAttendanceDate(date)}
          dateFormat="MM/dd/yyyy"
          className="date-picker"
        />
      </div>
      <div className="session-selector">
        <label>
          <input
            type="radio"
            value="morning"
            checked={session === 'morning'}
            onChange={() => setSession('morning')}
          />
          Morning Session
        </label>
        <label>
          <input
            type="radio"
            value="evening"
            checked={session === 'evening'}
            onChange={() => setSession('evening')}
          />
          Evening Session
        </label>
      </div>
      <ul className="student-list">
        {students.map(student => (
          <li key={student.id} className="student-item">
            <span className="student-name">{student.name}</span> {/* Display student name */}
            <div className="checkbox-group">
              <label className="checkbox-label">
                <input
                  type="radio"
                  name={`attendance-${student.id}`}
                  checked={student.status === 'present'}
                  onChange={() => toggleAttendance(student.id, 'present')}
                />
                Present
              </label>
              <label className="checkbox-label">
                <input
                  type="radio"
                  name={`attendance-${student.id}`}
                  checked={student.status === 'absent'}
                  onChange={() => toggleAttendance(student.id, 'absent')}
                />
                Absent
              </label>
            </div>
          </li>
        ))}
      </ul>
      <button className="btn" onClick={handleSubmit}>
        Submit Attendance
      </button>
    </div>
  );
};

export default Attendance;
