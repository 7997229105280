/**@format */

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminDashboard.css';
import attendanceImg from '../../assets/images/attendance.jpg';
import addStudentImg from '../../assets/images/add-student.jpg';
import removeStudentImg from '../../assets/images/remove-student.jpg';
import galleryImg from '../../assets/images/gallery.jpg';
import { authService } from '../../authService';


const AdminDashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0,0);
    // Check if the auth token exists in localStorage
    const authToken = localStorage.getItem('authToken');

    if (!authToken) {
      navigate('/LoginDashboard');
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/LoginDashboard');
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className='admin-dashboard'>
      <h1>Admin Dashboard</h1>
      <button className="logout-button" onClick={handleLogout}>Logout</button>
      <div className='dashboard-grid'>
        <div className='dashboard-item'>
          <img
            src={attendanceImg}
            alt='Take Attendance'
            onClick={() => handleNavigation('/TakeAttendance')}
          />
          <button className='btn' onClick={() => navigate('/TakeAttendance')}>Take Attendance</button>
        </div>
        <div className='dashboard-item'>
          <img
            src={addStudentImg}
            alt='Add Student'
            onClick={() => handleNavigation('/AddStudent')}
          />
          <button className='btn' onClick={() => navigate('/AddStudent')}>Add Student</button>
        </div>
        <div className='dashboard-item'>
          <img
            src={removeStudentImg}
            alt='Remove Student'
            onClick={() => handleNavigation('/RemoveStudent')}
          />
          <button className='btn' onClick={() => navigate('/RemoveStudent')}>Remove Student</button>
        </div>
        <div className='dashboard-item'>
          <img
            src={attendanceImg}
            alt='Retrieve Attendance'
            onClick={() => handleNavigation('/RetrieveAttendance')}
          />
          <button className='btn' onClick={() => navigate('/RetrieveAttendance')}>Retrieve Attendance</button>
        </div>
        <div className='dashboard-item'>
          <img
            src={galleryImg}
            alt='Add Media'
            onClick={() => handleNavigation('/AddMedia')}
          />
          <button className="btn" onClick={() => navigate('/AddMedia')}>Add Media</button>
        </div>
        <div className='dashboard-item'>
          <img
            src={galleryImg}
            alt='Remove Media'
            onClick={() => handleNavigation('/RemoveMedia')}
          />
          <button className='btn' onClick={() => navigate('/RemoveMedia')}>Remove Media</button>
        </div>
        <div className='dashboard-item'>
          <img
            src={removeStudentImg}
            alt='Removed Students'
            onClick={() => handleNavigation('/RemovedStudents')}
          />
          <button className='btn' onClick={() => navigate('/RemovedStudents')}>Removed Students</button>
        </div>

        
      </div>
    </div>
  );
};

export default AdminDashboard;
