/** @format */

import React from 'react';
import './Intro.css'; // Assuming you have some CSS for styling

const ShotokanKarate = () => {
  return (
    <section className="shotokan-karate-section">
      <h2>Shotokan Karate</h2>
      <p>
        Shotokan Karate is a traditional Japanese martial art developed by Gichin Funakoshi and his son Gigo Funakoshi. It is characterized by deep, long stances and powerful, linear movements. Shotokan emphasizes both physical and mental discipline, aiming to cultivate a strong spirit and a healthy body.
      </p>
      <h3>History</h3>
      <p>
        Shotokan Karate was founded in the early 20th century by Gichin Funakoshi, who is often referred to as the father of modern karate. He was instrumental in introducing karate to mainland Japan from Okinawa. The name "Shotokan" derives from "Shoto," Funakoshi's pen name, and "kan," meaning house or hall.
      </p>
      <h3>Principles</h3>
      <ul>
        <li><strong>Respect (Rei)</strong>: Practitioners show respect to their instructors, fellow students, and themselves.</li>
        <li><strong>Etiquette (Reishiki)</strong>: Emphasis on proper behavior and conduct in and out of the dojo.</li>
        <li><strong>Discipline (Shitsuke)</strong>: A commitment to rigorous training and self-improvement.</li>
        <li><strong>Perseverance (Nintai)</strong>: The ability to endure and persist in the face of challenges.</li>
      </ul>
      <h3>Training</h3>
      <p>Shotokan Karate training is divided into three main components:</p>
      <ul>
        <li><strong>Kihon</strong>: Basic techniques such as punches, kicks, and blocks.</li>
        <li><strong>Kata</strong>: Pre-arranged forms that simulate combat situations and teach combinations of techniques.</li>
        <li><strong>Kumite</strong>: Sparring with a partner to apply techniques in a controlled environment.</li>
      </ul>
      <h3>Benefits</h3>
      <ul>
        <li>Improved physical fitness and coordination</li>
        <li>Enhanced mental focus and discipline</li>
        <li>Increased self-confidence and self-defense skills</li>
        <li>Stress relief and mental well-being</li>
        <li>A sense of community and belonging</li>
      </ul>
    </section>
  );
};

export default ShotokanKarate;
