/** @format */

import React from "react";
import Slider from "react-slick";
/*import ava01 from "../../assets/images/ava-1.jpg";
import ava02 from "../../assets/images/ava-2.jpg";
import ava03 from "../../assets/images/ava-3.jpg";
<img src={ava03} className="avatar" alt="Robert Johnson" />*/
import "./Testimonials.css"; // Import your CSS file for styling

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonials-slider">
      <h2 className="section-title">What Our Students Say about us</h2>
      <Slider {...settings}>
        {/* Testimonial 1 */}
        <div className="testimonial">
          <p>
          Joining this karate school has been a transformative experience, helping me build confidence and discipline both on and off the mat.
          </p>
          <div className="testimonial-info">
            
            <div className="author">
              <h6>Sensie Supriya</h6>
              <span>1st Dan Black Belt</span>
            </div>
          </div>
        </div>

        {/* Testimonial 2 */}
        <div className="testimonial">
          <p>
          "The instructors are exceptional, providing personalized guidance that has greatly improved my technique and understanding of martial arts."


          </p>
          <div className="testimonial-info">
           
            <div className="author">
              <h6>Senpai Subramanya</h6>
              <span>Brown Belt</span>
            </div>
          </div>
        </div>

        {/* Testimonial 3 */}
        <div className="testimonial">
          <p>
          "The supportive community here makes every class enjoyable, and I've seen tremendous growth in my physical and mental strength."
          </p>
          <div className="testimonial-info">
            
            <div className="author">
              <h6>Senpai Srushti</h6>
              <span>Brown Belt</span>
            </div>
          </div>
        </div>
        <div className="testimonial">
          <p>
          "Joining this karate school has been a transformative experience, helping me build confidence and discipline both on and off the mat."
          </p>
          <div className="testimonial-info">
            
            
            
            <div className="author">
              <h6>Senpai Vinayak </h6>
              <span>Brown Belt</span>
            </div>
          </div>
        </div>
        <div className="testimonial">
          <p>
          "Training at this dojo has been transformative for me. The instructors are incredibly skilled and dedicated, creating a welcoming environment for all students. I've gained not only technical skills but also confidence and discipline."
          </p>
          <div className="testimonial-info">
            
            <div className="author">
              <h6>Senpai Shreya</h6>
              <span>Brown Belt</span>
            </div>
          </div>
        </div>
        <div className="testimonial">
          <p>
          "This dojo is more than just a place to learn karate—it's a community. The camaraderie among students and the dedication of the instructors make every session rewarding and enjoyable."
          </p>
          <div className="testimonial-info">
            
            <div className="author">
              <h6>Senpai Kush</h6>
              <span>Brown Belt</span>
            </div>
          </div>
        </div>
        <div className="testimonial">
          <p>
          "This is the place where you can find your inner strength, control of your mind."
          </p>
          <div className="testimonial-info">
            
            <div className="author">
              <h6>Senpai Shivakumar</h6>
              <span>Brown Belt</span>
            </div>
          </div>
        </div>
        <div className="testimonial">
          <p>
          "This dojo is a sanctuary for personal growth. It’s where you’ll find inner peace, conquer your fears, and develop the true character of a martial artist. Training here not only refines your skills but also purifies your spirit and builds resilience."
          </p>
          <div className="testimonial-info">
            
            <div className="author">
              <h6>Vinod</h6>
              
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Testimonials;
