import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './../styles/service5.css'

const MediaGallery = () => {
    useEffect(()=>{
        window.scrollTo(0,0);
    })
    const [mediaFiles, setMediaFiles] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch media file names from the backend using Axios
        axios.get('https://karatesportsclubhubli.com/fetch_media_files.php')
            .then(response => {
                //console.log('Response data:', response.data); // Debugging line
                if (Array.isArray(response.data)) {
                    // Generate full URLs for media files
                    const fileUrls = response.data.map(fileName => 
                        `https://karatesportsclubhubli.com/media_file.php?file=${encodeURIComponent(fileName)}`
                    );
                    setMediaFiles(fileUrls);
                } else {
                    throw new Error('Response data is not an array.');
                }
            })
            .catch(err => {
                console.error('Error fetching media files:', err);
                setError('Failed to load media files.');
            });
    }, []);

    return (
        <div>
            <h1>Media Gallery</h1>
            {error && <p>{error}</p>}
            <div className="gallery-container">
                <div className="gallery">
                    {mediaFiles.map((url, index) => (
                        <div key={index} className="gallery-item">
                            {/* Check if the URL points to an image or video */}
                            {url.match(/\.(jpeg|jpg|gif|png)$/) ? (
                                <img
                                    src={url}
                                    alt={`Media ${index}`}
                                />
                            ) : url.match(/\.(mp4|webm)$/) ? (
                                <video
                                    src={url}
                                    controls
                                />
                            ) : null}
                            <p>Media {index + 1}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MediaGallery;
