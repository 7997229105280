import React, { useState,useEffect } from 'react';
import axios from 'axios';
import './RetrieveAttendance.css';

const AttendanceReport = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
  })
  const [date, setDate] = useState('');
  const [session, setSession] = useState('morning');
  const [attendance, setAttendance] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchAttendance = async () => {
    if (!date) {
      setError('Please select a date.');
      return;
    }

    setLoading(true);
    setError('');
    
    try {
      const response = await axios.get('https://karatesportsclubhubli.com/RetrieveAttendance.php', {
        params: { date, session },
      });
      
      if (Array.isArray(response.data) && response.data.length > 0) {
        setAttendance(response.data);
      } else {
        setError('No attendance data found.');
        setAttendance([]);
      }
    } catch (error) {
      console.error('Error fetching attendance:', error);
      setError('There was an error fetching the attendance data.');
      setAttendance([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="attendance-report">
      <h2>Attendance Report</h2>
      <div className="filters">
        <label>
          Date:
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </label>
        <label>
          Session:
          <select
            value={session}
            onChange={(e) => setSession(e.target.value)}
          >
            <option value="morning">Morning</option>
            <option value="evening">Evening</option>
          </select>
        </label>
        <button onClick={fetchAttendance} disabled={loading}>
          {loading ? 'Loading...' : 'Fetch Attendance'}
        </button>
      </div>
      {error && <p className="error">{error}</p>}
      {attendance.length > 0 ? (
        <table className="attendance-table">
          <thead>
            <tr>
              <th>Student ID</th>
              <th>Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {attendance.map((record, index) => (
              <tr key={index}>
                <td>{record.student_id}</td>
                <td>{record.student_name}</td>
                <td>{record.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !loading && !error && <p>No attendance records found for the selected date and session.</p>
      )}
    </div>
  );
};

export default AttendanceReport;
