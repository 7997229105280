import React, { useState, useEffect } from 'react';
import axios from 'axios';

const RemoveStudent = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
  })
  const [students, setStudents] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState('');

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get('https://karatesportsclubhubli.com/GetAllStudents.php');
        setStudents(response.data);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };

    fetchStudents();
  }, []);

  const handleRemove = async () => {
    if (!selectedStudentId) {
      alert('Please select a student.');
      return;
    }

    try {
      const response = await axios.post('https://karatesportsclubhubli.com/RemoveStudent.php', {
        id: selectedStudentId
      });

      if (response.data.success) {
        alert('Student removed successfully.');
        setStudents(students.filter(student => student.id !== selectedStudentId));
      } else {
        alert('Error removing student.');
      }
    } catch (error) {
      console.error('Error removing student:', error);
      alert('Error removing student.');
    }
  };

  return (
    <div>
      <h2>Remove Student</h2>
      <select onChange={(e) => setSelectedStudentId(e.target.value)} value={selectedStudentId}>
        <option value="">Select a student</option>
        {students.map(student => (
          <option key={student.student_id} value={student.student_id}>{student.student_name}</option>
        ))}
      </select>
      <button onClick={handleRemove}>Remove Student</button>
    </div>
  );
};

export default RemoveStudent;
