import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './StudentProfile.css';

const StudentProfile = () => {
    const location = useLocation();
    const { studentId } = location.state; // Retrieve studentId passed during navigation

    const [studentDetails, setStudentDetails] = useState({
        student_id: '',
        name: '',
        mobile_no: '',
        email: '',
        student_image: ''
    });
    const [error, setError] = useState('');
    const [attendance, setAttendance] = useState([]);
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [session, setSession] = useState('morning');

    useEffect(() => {
        // Fetch student details on component mount
        const fetchStudentDetails = async () => {
            try {
                const response = await axios.post('https://karatesportsclubhubli.com/get_student_details.php', {
                    student_id: studentId
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
        
                console.log(response.data); // Log response data for debugging
        
                if (response.data.success) {
                    setStudentDetails({
                        ...response.data.student,
                        student_image: `data:image/jpeg;base64,${response.data.student.student_image}` // Embed base64 image
                    });
                } else {
                    setError(response.data.message || 'Error retrieving student details.');
                }
            } catch (err) {
                setError('An error occurred while fetching student details.');
                console.error('Error fetching student details:', err);
            }
        };

        fetchStudentDetails();
    }, [studentId]);

    const handleShowAttendance = async () => {
        try {
            const response = await axios.post('https://karatesportsclubhubli.com/getAttendance.php', {
                student_id: studentId,
                month,
                year,
                session
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.success) {
                setAttendance(response.data.attendance);
            } else {
                setError(response.data.message || 'Error retrieving attendance.');
                setAttendance([]); // Clear attendance if there's an error
            }
        } catch (err) {
            setError('An error occurred while fetching attendance.');
            console.error('Error fetching attendance:', err);
            setAttendance([]); // Clear attendance if there's an error
        }
    };

    return (
        <div className="student-profile-container">
            {error && <p className="error-message">{error}</p>}

            <div className="student-details-card">
                <div className="student-info">
                    <h2>Welcome {studentDetails.name}</h2>
                    <p><strong>Student ID:</strong> {studentDetails.student_id}</p>
                    <p><strong>Mobile No:</strong> {studentDetails.mobile_no}</p>
                    <p><strong>Email:</strong> {studentDetails.email}</p>
                </div>

                <div className="attendance-form">
                    <label>
                        Month:
                        <select value={month} onChange={(e) => setMonth(e.target.value)}>
                            <option value="">Select Month</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                    </label>

                    <label>
                        Year:
                        <input
                            type="number"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            placeholder="YYYY"
                            min="2000" // Adjust as needed
                            max={new Date().getFullYear()} // Ensure year is not in the future
                        />
                    </label>

                    <label>
                        Session:
                        <select value={session} onChange={(e) => setSession(e.target.value)}>
                            <option value="morning">Morning</option>
                            <option value="evening">Evening</option>
                        </select>
                    </label>

                    <button onClick={handleShowAttendance}>Show Attendance</button>
                </div>

                <div className="attendance-list">
                    {attendance.length > 0 ? (
                        <>
                            <h3>Attendance Records</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attendance.map((record, index) => (
                                        <tr key={index}>
                                            <td>{record.date}</td>
                                            <td>{record.status}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    ) : (
                        <p className="no-attendance">No attendance records available.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default StudentProfile;
