import {React, useEffect} from "react";
import { Container, Row, Col } from "reactstrap";
import "../styles/service1.css";

const Programs = () => {
  useEffect(()=> {
    window.scrollTo(0,0);
  },[]);
  return (
    <section className="programs">
      <Container>
        <Row>
          <Col lg="12" className="text-center">
            <h1>Our Programs</h1>
            <p>Explore the various training programs we offer!</p>
          </Col>
          <Col lg="4" className="program-item">
            <h3>Kids Program</h3>
            <p>Focus on discipline, basic techniques, and fun. Suitable for ages 5-12.</p>
          </Col>
          <Col lg="4" className="program-item">
            <h3>Adult Program</h3>
            <p>Emphasis on fitness, self-defense, and advanced techniques. Open to all skill levels.</p>
          </Col>
          <Col lg="4" className="program-item">
            <h3>Advanced Training</h3>
            <p>For higher belts, focusing on sparring, kata, and competition preparation.</p>
          </Col>
        </Row>
        <Row>
          <Col lg="4" className="program-item">
            <h3>Personality Development</h3>
            <p>Fosters self-confidence, discipline, and leadership skills through structured training and personal growth challenges.</p>
          </Col>
          <Col lg="4" className="program-item">
            <h3>Women's Self-Defense</h3>
            <p>Empowerment and safety techniques specifically for women. Build confidence and skills.</p>
          </Col>
          <Col lg="4" className="program-item">
            <h3>Special Workshops</h3>
            <p>Join our workshops on topics like kata, weapon training, and more. Check schedule for details.</p>
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="text-center">
            <h3>Instructor Profiles</h3>
            <p>Meet our experienced and dedicated instructors who guide you on your martial arts journey.</p>
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="text-center">
            <h3>How to Join</h3>
            <p>Contact us today to start your martial arts journey and become a part of our dojo family!</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Programs;
