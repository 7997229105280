import React, { useState, useRef,useEffect } from 'react';
import axios from 'axios';
import Webcam from 'react-webcam';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import './AddStudent.css';
import logoImage from '../../assets/images/shotokan.jpeg';


pdfMake.vfs = pdfFonts.pdfMake.vfs;

const AddStudent = () => {
  
  const [student, setStudent] = useState({
    name: '',
    password: '',
    aadhaarNo: '',
    aadhaarImage: null,
    studentImage: null,
    mobileNo: '',
    email: '',
    fatherName: '',
    fatherOccupation: '',
    motherName: '',
    motherOccupation: '',
    address: '',
    qualification: '',
    dateOfBirth: '',
    sex: '',
    weight: '',
    height: '',
    dateOfJoining: '',
    contactNo: '',
    dojoName: '',
    remarks: '',
    instructorName: '',
    chiefInstructorName: '',
  });

  const [showWebcam, setShowWebcam] = useState(false);
  const [studentImageURL, setStudentImageURL] = useState(null);
  const webcamRef = useRef(null);
  const formRef = useRef();

   // Check if page should scroll to the top
   useEffect(() => {
    const shouldScrollToTop = sessionStorage.getItem('navigate');
    if (shouldScrollToTop === 'true') {
      window.scrollTo(0, 0);
      sessionStorage.removeItem('navigate');
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setStudent((prev) => ({
      ...prev,
      [name]: type === 'file' ? files[0] : value,
    }));
  };

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], 'studentImage.jpg', { type: 'image/jpeg' });
        setStudent((prev) => ({
          ...prev,
          studentImage: file,
        }));
        setStudentImageURL(URL.createObjectURL(blob));
        setShowWebcam(false);
      });
  };

  const handleRetakeImage = () => {
    setStudentImageURL(null);
    setShowWebcam(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      for (const key in student) {
        formData.append(key, student[key]);
      }

      const response = await axios.post('https://karatesportsclubhubli.com/add_student.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      const responseText = response.data;
      const jsonString = responseText.match(/{.*}/)[0]; // Extract the JSON part
      const parsedResponse = JSON.parse(jsonString);    // Parse the JSON
      const student_id = parsedResponse.student_id;     // Extract the student ID
      console.log(student_id);   
      
        
      console.log('Sending student name:', student.name);
        // Send student's name to ForAttendance database
      await axios.post('https://karatesportsclubhubli.com/forattendance.php', {studentName:student.name, student_id:student_id  });

      
      alert('Student added successfully!');
      

      

      // Generate certificate and send to email
      generateAndSendCertificate({ ...student, student_id});

      // Optionally, reset the form fields after successful submission
      setStudent({
        name: '',
        password: '',
        aadhaarNo: '',
        aadhaarImage: null,
        studentImage: null,
        mobileNo: '',
        email: '',
        fatherName: '',
        fatherOccupation: '',
        motherName: '',
        motherOccupation: '',
        address: '',
        qualification: '',
        dateOfBirth: '',
        sex: '',
        weight: '',
        height: '',
        dateOfJoining: '',
        contactNo: '',
        dojoName: '',
        remarks: '',
        instructorName: '',
        chiefInstructorName: '',
      });
      setStudentImageURL(null);
    } catch (error) {
      console.error('Error adding student:', error);
      alert('Failed to add student. Please try again.');
    }
  };

  const getImageDataURL = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const generateAndSendCertificate = async (studentData,student_id) => {
    const studentImageDataURL = studentData.studentImage ? await getImageDataURL(studentData.studentImage) : null;
  
    const logoResponse = await fetch(logoImage);
    const logoBlob = await logoResponse.blob();
    const logoImageDataURL = await getImageDataURL(logoBlob);
    const docDefinition = {
      pageSize: 'A4',
      pageMargins: [15, 15, 15, 15], // Even smaller margins for more content space
      content: [
        {
          image: logoImageDataURL,
          width: 90, // Slightly smaller logo
          alignment: 'center',
          margin: [0, -50], // Reduced margin for a more compact look
        },
        {
          text: 'Reg.No.: DWR-S437-2015/16',
          style: 'content',
          alignment: 'right',
          margin: [0, 0, 10, 5], // Slight adjustment in margin for better balance
        },
        {
          text: 'SHOTOKAN KARATE-DO ASSOCIATION\nYOUTH SPORTS CLUB, HUBBALLI KARNATAKA',
          style: 'header',
          alignment: 'center',
          margin: [0, 10], // Slightly reduced spacing
        },
        {
          text: 'Dojo H.Q: Shri Siddeshwara Kailasa Mantapa Hosamath P.B Road, Unkal, Hubballi-31.',
          style: 'content',
          alignment: 'center',
          margin: [0, -5], // Reduced space
        },
        {
          text: 'Cell: 9916836930   Email: shotokankaratehubli@gmail.com',
          style: 'content',
          alignment: 'center',
          margin: [0, 5],
        },
        {
          text: 'SENSEI: RAJESH.B.YARAGATTI\nBlack Belt 3rd Dan, President S.K.A.Y.S.C',
          style: 'left',
          alignment: 'left',
          margin: [0, 5], // Keep consistent with minimal space
        },
        {
          text: 'Certificate of Enrollment',
          style: 'title',
          alignment: 'center',
          margin: [0, 10], // Slightly spaced title for emphasis
        },
        {
          columns: [
            {
              width: '*',
              stack: [
                {
                  text: 'This is to certify that the following student has been enrolled:',
                  style: 'content',
                  margin: [0, 10, 0, 10], // Streamlined spacing between lines
                },
                {
                  text: `Name: ${studentData.name}`,
                  style: 'content',
                  margin:[0,3],
                },
                {
                  text: `Student ID: ${studentData.student_id}`,
                  style: 'content',
                  margin:[0,3],
                },
                {
                  text: `Password: ${studentData.password}`,
                  style: 'content',
                  margin:[0,3],
                },
                {
                  text: `Father's Name: ${studentData.fatherName}`,
                  style: 'content',
                  margin:[0,3],
                },
                {
                  text: `Father's Occupation: ${studentData.fatherOccupation}`,
                  style: 'content',
                  margin:[0,3],
                },
                {
                  text: `Mother's Name: ${studentData.motherName}`,
                  style: 'content',
                  margin:[0,3],
                },
                {
                  text: `Mother's Occupation: ${studentData.motherOccupation}`,
                  style: 'content',
                  margin:[0,3],
                },
                {
                  text: `Address: ${studentData.address}`,
                  style: 'content',
                  margin:[0,3],
                },
                {
                  text: `Qualification: ${studentData.qualification}`,
                  style: 'content',
                  margin:[0,3],
                },
                {
                  text: `Date of Birth: ${studentData.dateOfBirth}`,
                  style: 'content',
                  margin:[0,3],
                },
                {
                  text: `Sex: ${studentData.sex}`,
                  style: 'content',
                  margin:[0,3],
                },
                {
                  text: `Weight: ${studentData.weight}`,
                  style: 'content',
                  margin:[0,3],
                },
                {
                  text: `Height: ${studentData.height}`,
                  style: 'content',
                  margin:[0,3],
                },
                {
                  text: `Date of Joining: ${studentData.dateOfJoining}`,
                  style: 'content',
                  margin:[0,3],
                },
                {
                  text: `Contact No: ${studentData.contactNo}`,
                  style: 'content',
                  margin:[0,3],
                },
                {
                  text: `Mobile No: ${studentData.mobileNo}`,
                  style: 'content',
                  margin:[0,3],
                },
                {
                  text: `Email: ${studentData.email}`,
                  style: 'content',
                  margin:[0,3],
                },
                {
                  text: `Instructor Name: ${studentData.instructorName}`,
                  style: 'content',
                  margin:[0,3],
                },
                {
                  text: `Chief Instructor Name: ${studentData.chiefInstructorName}`,
                  style: 'content',
                  margin:[0,3],
                },
              ],
            },
            {
              width: 'auto',
              image: studentImageDataURL,
              width: 80, // Smaller image
              alignment: 'right',
              margin: [5, 25], // Adjusted for alignment with text
            },
          ],
        },
        {
          text: 'Declaration',
          style: 'subheader',
          margin: [0, 20], // Reduced margin for better spacing
        },
        {
          ul: [
            'Organizing committee is not responsible for any injury, but first aid will be provided.',
            'Admission fee will not be refunded.',
          ],
          style: 'content',
        },
        {
          text: 'I, Mr. / Mrs. ___________________________________ declare that the above statements are true to the best of my knowledge and promise to obey the rules and regulations of the organization.',
          style: 'content',
          margin: [0, 20], // Adjust spacing for better flow
        },
        {
          width: '*',
          text: 'Signature of Candidate/Parent: __________',
          style: 'content',
          margin: [0, 5],
        },
        {
          text: 'FOR OFFICE USE ONLY',
          style: 'subheader',
          margin: [0, 10],
        },
        {
          text: `Name of the Dojo: ${studentData.dojoName}`,
          style: 'content',
          margin:[0,5],
        },
        {
          text: `Remarks and Renewal Details: ${studentData.remarks}`,
          style: 'content',
          margin:[0,20],
        },
        {
          columns: [
            {
              width: '*',
              text: 'Signature of Instructor: __________',
              style: 'content',
            },
            {
              width: '*',
              text: 'Signature of Chief Instructor: __________',
              style: 'content',
            },
          ],
        },
      ],
      styles: {
        header: {
          fontSize: 13, // Reduced header font size
          bold: true,
          color: '#003366',
          alignment: 'center',
        },
        title: {
          fontSize: 15, // Reduced title font size
          bold: true,
          color: '#005b96',
          alignment: 'center',
        },
        subheader: {
          fontSize: 11, // Adjusted subheader font size
          bold: true,
          color: '#003366',
          alignment: 'center',
        },
        content: {
          fontSize: 9, // Compact content font size
          color: '#333333',
        },
      },
      defaultStyle: {
        font: 'Roboto',
      },
    };
    
    
    
    
  
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
  
    // Download the PDF
    pdfDocGenerator.download(`certificate_${studentData.name}.pdf`);
    pdfDocGenerator.getBase64(async (data) => {
      try {
        const response = await axios.post('https://karatesportsclubhubli.com//certificatesmail.php', {
          email: studentData.email,
          pdfData: data,
           // Password
        });
        alert('Certificates are sent successfully to the mail');
        
  
        
      } catch (error) {
        console.error('Error sending certificate email:', error);
        alert('Failed to send certificate email. Please try again.');
      }
    });
  };
  
  
  return (
    <div className="add-student-container">
      <form className="student-form" onSubmit={handleSubmit} ref={formRef}>
        <div className="form-header">
          <h2>Student Registration</h2>
          {studentImageURL && <img src={studentImageURL} alt="Student" className="student-image" />}
        </div>
        <div className="form-section">
          <div className="form-column">
            <label>
              Name:
              <input type="text" name="name" value={student.name} onChange={handleChange} required />
            </label>
            <label>
              Password:
              <input type="password" name="password" value={student.password} onChange={handleChange} required />
            </label>
            <label>
              Aadhaar No:
              <input type="text" name="aadhaarNo" value={student.aadhaarNo} onChange={handleChange} required />
            </label>
            <label>
              Aadhaar Image:
              <input type="file" name="aadhaarImage" onChange={handleChange} required />
            </label>
            <label>
              Contact No:
              <input type="number" name="mobileNo" value={student.mobileNo} onChange={handleChange} required />
            </label>
            <label>
              Email:
              <input type="email" name="email" value={student.email} onChange={handleChange} required />
            </label>
          </div>
          <div className="form-column">
            <label>
              Father's Name:
              <input type="text" name="fatherName" value={student.fatherName} onChange={handleChange} required />
            </label>
            <label>
              Father's Occupation:
              <input type="text" name="fatherOccupation" value={student.fatherOccupation} onChange={handleChange} required />
            </label>
            <label>
              Mother's Name:
              <input type="text" name="motherName" value={student.motherName} onChange={handleChange} required />
            </label>
            <label>
              Mother's Occupation:
              <input type="text" name="motherOccupation" value={student.motherOccupation} onChange={handleChange} required />
            </label>
            <label>
              Address:
              <textarea name="address" value={student.address} onChange={handleChange} required />
            </label>
            <label>
              Qualification:
              <input type="text" name="qualification" value={student.qualification} onChange={handleChange} required />
            </label>
          </div>
          <div className="form-column">
            <label>
              Date of Birth:
              <input type="date" name="dateOfBirth" value={student.dateOfBirth} onChange={handleChange} required />
            </label>
            <label>
              Sex:
              <input type="text" name="sex" value={student.sex} onChange={handleChange} required />
            </label>
            <label>
              Weight:
              <input type="text" name="weight" value={student.weight} onChange={handleChange} required />
            </label>
            <label>
              Height:
              <input type="text" name="height" value={student.height} onChange={handleChange} required />
            </label>
            <label>
              Date of Joining:
              <input type="date" name="dateOfJoining" value={student.dateOfJoining} onChange={handleChange} required />
            </label>
            <label>
              Alt Contact No:
              <input type="number" name="contactNo" value={student.contactNo} onChange={handleChange} required />
            </label>
          </div>
          <div className="form-column">
            <label>
              Dojo Name:
              <input type="text" name="dojoName" value={student.dojoName} onChange={handleChange} required />
            </label>
            <label>
              Remarks:
              <input type="text" name="remarks" value={student.remarks} onChange={handleChange} />
            </label>
            <label>
              Instructor Name:
              <input type="text" name="instructorName" value={student.instructorName} onChange={handleChange} required />
            </label>
            <label>
              Chief Instructor Name:
              <input type="text" name="chiefInstructorName" value={student.chiefInstructorName} onChange={handleChange} required />
            </label>
            <div className="student-image-box">
              <label>
                Student Image:
                <input type="file" name="studentImage" onChange={handleChange} />
              </label>
              {showWebcam ? (
                <div>
                  <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
                  <button type="button" onClick={captureImage}>
                    Capture Image
                  </button>
                </div>
              ) : (
                <div>
                  <button type="button" onClick={() => setShowWebcam(true)}>
                    Take a Picture
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AddStudent;
