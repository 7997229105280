import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link , useNavigate} from 'react-router-dom';
import serviceImg1 from '../../assets/images/sir.jpeg'; // Replace with your image path
import serviceImg2 from '../../assets/images/pradeep_sir.jpeg'; // Replace with your image path
import './ServiceSection.css'; // Import your CSS file for styling

const ServicesSection = () => {
  const Navigate = useNavigate();
  return (
    <section className="services-section">
      <Container>
        <Row className="align-items-center">
          <Col lg="4">
            <div className="sidebar">
              <h5 className="subtitle">What We Offer</h5>
              <h2 className="service-title">Explore Our Services</h2>
              <div className="resource-buttons">
                <Link to="/service1" className="service-link">
                  Programs
                </Link>
                <Link to="/service2" className="service-link">
                  Membership
                </Link>
                
                <Link to="/service5" className="service-link">
                  Gallery
                </Link>
                <Link to="/service6" className="service-link">
                  Shotokan Training
                </Link>
              </div>
            </div>
          </Col>
          <Col lg="8">
            <Row>
              <Col md="6" className="service-item">
                <div className="service-card">
                  <img src={serviceImg1} alt="Service 1" className="service-image" />
                  <div className="service-content">
                    <h3 className="service-heading">Sensei Rajesh B. Yaragatti</h3>
                    <p className="service-description">
                      A highly skilled karate master known for his dedication to teaching and exceptional expertise in martial arts. His passion for karate and commitment to his students' growth make him a respected leader in the community.
                    </p>
                    <button onClick={() => Navigate( '/RajeshSirDetails')} className="btn btn-primary">
                      Learn More
                    </button>
                  </div>
                </div>
              </Col>
              <Col md="6" className="service-item">
                <div className="service-card">
                  <img src={serviceImg2} alt="Service 2" className="service-image" />
                  <div className="service-content">
                    <h3 className="service-heading">Shihan: Pradeep Kumar</h3>
                    <p className="service-description">
                      Master Pradeep, the esteemed instructor, has left a lasting legacy in the martial arts community. His teachings continue to inspire and guide through his dedicated students.
                    </p>
                    <button onClick={() => Navigate( '/PradeepSirDetails')} className="btn btn-primary">
                      Learn More
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ServicesSection;
