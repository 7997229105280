import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const LoginForm = () => {
    const [adminEmail, setAdminEmail] = useState('');
    const [adminPassword, setAdminPassword] = useState('');
    const [studentId, setStudentId] = useState('');
    const [studentPassword, setStudentPassword] = useState('');
    const [formType, setFormType] = useState('admin');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleAdminSubmit = async (event) => {
        event.preventDefault();
        if (!adminEmail || !adminPassword) {
            setError('Please fill in all fields.');
            return;
        }
        try {
            const response = await axios.post('https://karatesportsclubhubli.com/admin_login.php', {
                email: adminEmail,
                password: adminPassword
            });

            if (response.data.success) {
                localStorage.setItem('authToken', response.data.token);
                navigate('/AdminDashboard');
            } else {
                setError(response.data.message);
            }
        } catch (err) {
            setError('An error occurred. Please try again.');
            console.error('API Error:', err);
        }
    };

    const handleStudentSubmit = async (event) => {
        event.preventDefault();
        if (!studentId || !studentPassword) {
            setError('Please fill in all fields.');
            return;
        }
        try {
            const response = await axios.post('https://karatesportsclubhubli.com/student_login.php', {
                student_id: studentId,
                password: studentPassword
            });

            if (response.data.success) {
                navigate('/StudentProfile', { state: { studentId: response.data.student_id } });
            } else {
                setError(response.data.message);
            }
        } catch (err) {
            setError('Invalid student credentials.');
            console.error('Error during login:', err);
        }
    };

    const handleFormToggle = (type) => {
        setFormType(type);
        setError('');
    };

    return (
        <div className="login-container">
            <h2>{formType === 'admin' ? 'Admin Login' : 'Student Login'}</h2>
            <div className="form-selection">
                <button
                    className={formType === 'admin' ? 'active' : ''}
                    onClick={() => handleFormToggle('admin')}
                >
                    Admin Login
                </button>
                <button
                    className={formType === 'student' ? 'active' : ''}
                    onClick={() => handleFormToggle('student')}
                >
                    Student Login
                </button>
            </div>

            <div className={`login-form ${formType === 'admin' ? 'admin-login active' : 'student-login'}`}>
                {error && <p className="error-message">{error}</p>}
                <form onSubmit={formType === 'admin' ? handleAdminSubmit : handleStudentSubmit}>
                    <div className="form-group">
                        <label htmlFor={`${formType === 'admin' ? 'admin' : 'student'}-email`}>
                            {formType === 'admin' ? 'Email' : 'Student ID'}:
                        </label>
                        <input
                            type="text"
                            id={`${formType === 'admin' ? 'admin' : 'student'}-email`}
                            value={formType === 'admin' ? adminEmail : studentId}
                            onChange={(e) =>
                                formType === 'admin'
                                    ? setAdminEmail(e.target.value)
                                    : setStudentId(e.target.value)
                            }
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor={`${formType === 'admin' ? 'admin' : 'student'}-password`}>
                            Password:
                        </label>
                        <input
                            type="password"
                            id={`${formType === 'admin' ? 'admin' : 'student'}-password`}
                            value={formType === 'admin' ? adminPassword : studentPassword}
                            onChange={(e) =>
                                formType === 'admin'
                                    ? setAdminPassword(e.target.value)
                                    : setStudentPassword(e.target.value)
                            }
                            required
                        />
                    </div>
                    <button type="submit">Login</button>
                </form>
            </div>
        </div>
    );
};

export default LoginForm;
