import React from "react";
import { Container, Row, Col } from "reactstrap";
//import './Introduction.css'

const HeroSection = ({ heroImg, heroImg03 }) => {
  return (
    <section className="hero">
      <Container>
        <Row className="align-items-center">         
         <Col lg="6">
            <div className="hero-content">
            <h1 className="clubHeading">Karate Sports Club Hubballi</h1>
              <h1>Welcome to the world of Martial Arts</h1>
              <p>
                "Shotokan Karate-Do Association Youth Sports Club, Hubballi
                Karnataka, believes in achieving disciplined accomplishment
                (siddhi) through mastery of hands, mind, and vision, coupled
                with precision in target practice."
              </p>
            </div>
          </Col>
          <Col lg="3" md="6" sm="6">
            <div className="hero-img-box logo">
              <img src={heroImg} alt="company logo" />
            </div>
          </Col>
          <Col lg="3" md="6" sm="6">
            <div className="hero-img-box master">
              <img src={heroImg03} alt="Martial arts master" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
