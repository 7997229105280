import React, { useRef, useEffect, useContext } from "react";
import { Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/shotokan1.jpg";
import "./header.css";
import { AuthContext } from "../../context/AuthContext";

const navLinks = [
  { path: "/home", display: "Home" },
  { path: "/about", display: "About" },
  { path: "/service1", display: "Programs"},
  { path: "/service2", display:"Membership"},
  
  { path: "/service5", display: "Gallery"},
  { path: "/service6", display: 'Shotokan Training'}
];

const Header = () => {
  const headerRef = useRef(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const { user, dispatch } = useContext(AuthContext);

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  useEffect(() => {
    const stickyHeaderFunc = () => {
      if (window.scrollY > 80) {
        headerRef.current.classList.add("sticky_header");
      } else {
        headerRef.current.classList.remove("sticky_header");
      }
    };

    window.addEventListener("scroll", stickyHeaderFunc);
    return () => window.removeEventListener("scroll", stickyHeaderFunc);
  }, []);

  const toggleMenu = () => menuRef.current.classList.toggle("show_menu");

  const handleLinkClick = () => {
    menuRef.current.classList.remove("show_menu");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        menuRef.current.classList.remove("show_menu");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <header className="header" ref={headerRef}>
      <Container>
        <Row>
          <div className="nav_wrapper d-flex align-items-center justify-content-between">
            <div className="logo d-flex align-items-center">
              <Link to="/home">
                <img src={logo} alt="Shotokan Karate-Do Association" />
              </Link>
              <Link to="/home" className="club_name">
                SHOTOKAN KARATE-DO-ASSOCIATION YOUTH SPORTS CLUB&reg; HUBBALLI KARNATAKA
              </Link>
            </div>
            <div className="nav_right d-flex align-items-center gap-4">
              <span className="mobile_menu" onClick={toggleMenu}>
                &#9776;
              </span>
            </div>
            <div className="navigation" ref={menuRef}>
              <ul className="menu d-flex flex-column align-items-center gap-2">
                {navLinks.map((item, index) => (
                  <li className="nav_item" key={index}>
                    <Link
                      to={item.path}
                      className={(navClass) => (navClass.isActive ? "active_link" : "")}
                      onClick={handleLinkClick}
                    >
                      {item.display}
                    </Link>
                  </li>
                ))}
                {user && (
                  <li className="nav_item">
                    <Link to="/" className="nav_link" onClick={logout}>
                      Logout
                    </Link>
                  </li>
                )}
                {!user && (
                  <li className="nav_item">
                    <Link to="/LoginDashboard" className="nav_link" onClick={handleLinkClick}>
                      Login
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
