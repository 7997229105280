import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddBackStudent = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
  })
  const [removedStudents, setRemovedStudents] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState('');

  useEffect(() => {
    const fetchRemovedStudents = async () => {
      try {
        const response = await axios.get('https://karatesportsclubhubli.com/GetRemovedStudents.php');
        setRemovedStudents(response.data);
      } catch (error) {
        console.error('Error fetching removed students:', error);
      }
    };

    fetchRemovedStudents();
  }, []);

  const handleAddBack = async () => {
    if (!selectedStudentId) {
      alert('Please select a student.');
      return;
    }

    try {
      const response = await axios.post('https://karatesportsclubhubli.com/AddBackStudent.php', {
        id: selectedStudentId
      });

      if (response.data.success) {
        alert('Student added back successfully.');
        setRemovedStudents(removedStudents.filter(student => student.student_id !== selectedStudentId));
      } else {
        alert('Error adding back student.');
      }
    } catch (error) {
      console.error('Error adding back student:', error);
      alert('Error adding back student.');
    }
  };

  return (
    <div>
      <h2>Add Back Student</h2>
      <select onChange={(e) => setSelectedStudentId(e.target.value)} value={selectedStudentId}>
        <option value="">Select a student</option>
        {removedStudents.map(student => (
          <option key={student.student_id} value={student.student_id}>{student.student_name}</option>
        ))}
      </select>
      <button onClick={handleAddBack}>Add Back Student</button>
    </div>
  );
};

export default AddBackStudent;
