import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BackButtonHandler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleBackButton = (event) => {
      if (window.history.state && window.history.state.idx > 0) {
        navigate(-1);  // Go back in history
      } else {
        // Handle the case when you want to exit the app
        if (window.confirm('Do you really want to exit the app?')) {
          window.close();  // Or perform custom action for exiting
        } else {
          // Prevent the default back button action
          event.preventDefault();
        }
      }
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate]);

  return null;
};

export default BackButtonHandler;
