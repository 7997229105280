import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/service3.css'


const Events = () => {
  const [mediaItems, setMediaItems] = useState([]);

  useEffect(() => {

    window.scrollTo(0,0);
    const fetchMedia = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/media1');
        setMediaItems(response.data);
      } catch (error) {
        console.error('Error fetching media:', error);
      }
    };

    fetchMedia();
  }, []);

  return (
    <div className="gallery-container">
      <h2>Events Gallery</h2>
      <div className="gallery">
        {mediaItems.map(media1 => (
          <div key={media1._id} className="gallery-item">
            {media1.type.startsWith('image') ? (
              <img src={`http://localhost:5000/${media1.url}`} alt="media" />
            ) : (
              <video controls>
                <source src={`http://localhost:5000/${media1.url}`} type={media1.type} />
              </video>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Events;

