import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import './SendEmails.css';

const SendEmails = () => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [studentEmails, setStudentEmails] = useState([]);

  useEffect(() => {
    const fetchStudentEmails = async () => {
      try {
        const response = await axios.get('http://localhost/BackendServerCosmo/GetAllStudentEmails.php');
        if (Array.isArray(response.data)) {
          setStudentEmails(response.data);
        } else {
          console.error('Unexpected response format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching student emails:', error);
      }
    };

    fetchStudentEmails();
  }, []);

  const handleAttachmentChange = (e) => {
    setAttachment(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('message', message);
    if (attachment) {
      formData.append('attachment', attachment);
    }
    formData.append('emails', JSON.stringify(studentEmails));

    try {
      await axios.post('http://localhost/BackendServerCosmo/SendEmails.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Emails sent successfully!');
      setSubject('');
      setMessage('');
      setAttachment(null);
    } catch (error) {
      console.error('Error sending emails:', error);
      alert('There was an error sending the emails.');
    }
  };

  return (
    <div className="send-emails">
      <h2>Send Emails to Students</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Subject:</label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Message:</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Attachment (optional):</label>
          <input
            type="file"
            onChange={handleAttachmentChange}
          />
        </div>
        <button type="submit" className="btn">Send Emails</button>
      </form>
    </div>
  );
};

export default SendEmails;
