import {React,useEffect} from 'react';
import './About.css';

import RajeshImage from '../../assets/images/sir.jpeg';
import PradeepImage from '../../assets/images/pradeep_sir.jpeg';
import Sensei_chinmayee from '../../assets/images/Sensei_chinmayee.jpeg';
import Sensei_munaf from '../../assets/images/Sensei_munaf.jpeg';

const KarateComponent = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
  })
  const locations = [
    {
      name: 'Shree Siddeshwara Kailasa Mantapa Hosamath P.B Road, Unkal Hubballi-31',
      mapLink: 'https://www.google.co.in/maps/place/Shotokan+karate+sports+club+unkal/@15.3766879,75.1107688,17z/data=!3m1!4b1!4m6!3m5!1s0x3bb8d18c2fa3cd9d:0x50d008c233f0cca1!8m2!3d15.3766879!4d75.1133437!16s%2Fg%2F11qkqvhs5z?entry=ttu',
      timings: [
        'Monday, Wednesday, Friday - Morning: 6:00-7:30 AM, Evening: 6:00-7:30 PM',
      ],
    },
    {
      name: 'Kalmeshwara Temple Samudaya Bhavana, Unkal Hubballi-31',
      mapLink: 'https://www.google.co.in/maps/place/Sri+Kalmeshwar+Temple/@15.3797857,75.114197,18.36z/data=!4m14!1m7!3m6!1s0x3bb8d18c2fa3cd9d:0x50d008c233f0cca1!2sShotokan+karate+sports+club+unkal!8m2!3d15.3766879!4d75.1133437!16s%2Fg%2F11qkqvhs5z!3m5!1s0x3bb8d0d0993613a3:0xf4e07788afe7ae5e!8m2!3d15.3799038!4d75.1154006!16s%2Fg%2F11b75q8jgr?entry=ttu',
      timings: [
        'Tuesday, Thursday, Sunday - Morning: 6:00-7:30 AM',
      ],
    },
    {
      name: 'Dr. B.R. Ambedkar Samudaya Bhavana, Teachers colony, Sai Nagar,Unkal Hubballi-31',
      mapLink: 'https://maps.app.goo.gl/Qwi9NFdLH5DsMnVW6',
      timings: [
        'Tuesday, Thursday, Saturday - Evening: 6:00-7:30 PM',
      ],
    },
  ];

  return (
    <div className="karate-container">
      <h1>About Our Dojo</h1>
      <div className="masters">
        <h2>Meet Our Masters</h2>
        <div className="master">
          <img src={RajeshImage} alt="Chief Instructor Rajesh B Yaragatti" className="master-image" />
          <div className="master-info">
            <p><strong>Chief Instructor:Rajesh B Yaragatti</strong> - 3rd Dan Black Belt</p>
            <p>Rajesh Sir has been Instructing Shotokan Karate for over 20 years. His dedication to the art is unmatched, and he is known for his rigorous training methods and emphasis on discipline. Under his guidance, many students have achieved black belts and have gone on to win national and international championships.</p>
          </div>
        </div>
        <div className="master">
          <img src={PradeepImage} alt="Master Pradeep" className="master-image" />
          <div className="master-info">
            <p><strong>Shihon: Pradeep Kumar</strong> - 5th Dan Black Belt (In Memory)</p>
            <p>Pradeep Sir was a revered master known for his profound knowledge and skill in Shotokan Karate. His teachings continue to inspire and guide us. His legacy lives on through his students and the values he instilled in the dojo.</p>
          </div>
        </div>
        <div className="master">
          <img src={Sensei_chinmayee} alt="Sensei chinmayee" className="cmaster-image" />
          <div className="master-info">
            <p><strong>Instructor: Chinmayee R. Yaragatti</strong> - 1st Dan Black Belt </p>
            <p>Sensei Chinmayee R. Yaragatti is a distinguished 1st Dan black belt in Shotokan Karate, trained under the esteemed Sensei Rajesh B. Yaragatti. Since embarking on her karate journey in 2012, Sensei Chinmayee has emerged as a prominent instructor, renowned for her skill, dedication, and passion for the art. Her journey from a dedicated practitioner to a respected teacher highlights her profound commitment to karate and her ability to inspire and guide her students with expertise and enthusiasm.</p>
          </div>
        </div>
        <div className="master">
          <img src={Sensei_munaf} alt="Sensei Munaf" className="master-image" />
          <div className="master-info">
            <p><strong>Instructor: Munaf S. Kanwar</strong> - 1st Dan Black Belt </p>
            <p>Munaf S. Kanwar holds a 1st Dan black belt in Shotokan Karate, achieved under the mentorship of Sensei Rajesh B. Yaragatti. Since commencing his practice in 2013, Munaf has grown into a skilled instructor, celebrated for his dedication and technical proficiency. His journey from an enthusiastic practitioner to a trusted teacher showcases his unwavering commitment to the principles of Shotokan Karate and his passion for fostering the development of his students.</p>
          </div>
        </div>
      </div>
      <div className="philosophy">
        <h2>Our Philosophy</h2>
        <p>
          Shotokan Karate is not just a martial art, but a way of life. It teaches discipline, respect, and perseverance. 
          Our training not only improves physical strength and flexibility but also nurtures mental fortitude. 
          As Master Gichin Funakoshi, the founder of Shotokan karate, once said: 
          <blockquote>
            "The ultimate aim of karate lies not in victory nor defeat, but in the perfection of the character of its participants."
          </blockquote>
        </p>
      </div>
      <div className="history">
        <h2>Brief History of Shotokan Karate</h2>
        <p>
          Shotokan Karate was developed by Gichin Funakoshi, who is considered the father of modern karate. 
          It emphasizes powerful linear techniques and deep stances. Shotokan has become one of the most popular karate styles worldwide.
        </p>
      </div>
      <div className="locations">
        <h2>Training Locations</h2>
        {locations.map((location, index) => (
          <div key={index} className="location">
            <a href={location.mapLink} target="_blank" rel="noopener noreferrer">{location.name}</a>
            <ul>
              {location.timings.map((timing, i) => (
                <li key={i}>{timing}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default KarateComponent;
