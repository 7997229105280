import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AddMedia.css';

const AddMedia = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0); // For upload progress
  const [estimatedTime, setEstimatedTime] = useState(null); // For estimated time

  let startTime;

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setMessage('Please select a file before uploading.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setLoading(true);
    setMessage('');
    setProgress(0);
    setEstimatedTime(null);

    try {
      startTime = Date.now(); // Capture the start time for estimating upload duration

      const response = await axios.post('https://karatesportsclubhubli.com/AddMedia.php', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.round((loaded * 100) / total);
          setProgress(percentage);

          // Estimate the time remaining
          const elapsedTime = (Date.now() - startTime) / 1000; // in seconds
          const uploadSpeed = loaded / elapsedTime; // bytes per second
          const remainingTime = (total - loaded) / uploadSpeed; // in seconds
          setEstimatedTime(Math.round(remainingTime)); // in seconds, rounded
        },
      });
      setMessage('File uploaded successfully');
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('Error uploading file');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-media-container">
      <h2>Upload Media</h2>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit" disabled={loading}>
          {loading ? 'Uploading...' : 'Upload'}
        </button>
      </form>
      {loading && (
        <div>
          <p>Please wait, your media is uploading...</p>
          <p>Progress: {progress}%</p>
          {estimatedTime !== null && <p>Estimated time remaining: {estimatedTime} seconds</p>}
        </div>
      )}
      {message && <p>{message}</p>}
    </div>
  );
};

export default AddMedia;
