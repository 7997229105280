import {React,useEffect} from "react";
import { Container, Row, Col } from "reactstrap";
import "../styles/service2.css";

const Membership = () => {
  useEffect(()=> {
    window.scrollTo(0,0);
  },[]);
  return (
    <section className="membership">
      <Container>
        <Row>
          <Col lg="12" className="text-center mb-5">
            <h1>Membership Details</h1>
            <p>Join our dojo and start your martial arts journey today!</p>
          </Col>
          <Col lg="4" className="membership-item">
            <h3>Admission + Dress + Membership</h3>
            <p>Includes a karate uniform to start your training.</p>
            <div className="price">₹2000</div>
          </Col>
          <Col lg="4" className="membership-item">
            <h3>Monthly Fee</h3>
            <p>Regular access to classes and training sessions.</p>
            <div className="price">₹550</div>
          </Col>
          <Col lg="4" className="membership-item">
            <h3>Yearly Fee</h3>
            <p>Save by paying upfront for the entire year.</p>
            <div className="price">₹6000</div>
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="membership-item grading">
            <h3>Grading Fees</h3>
            <p>Fees vary according to belt level. Contact us for details.</p>
            <a
              href="https://karatesportsclubhubli.com/GradingForm.pdf"
              download="GradingForm.pdf"
              className="download-link"
            >
              Download Grading Form
            </a>
          </Col>
          
        </Row>
        <Row></Row>
      </Container>
    </section>
  );
};

export default Membership;
